$sidebar-bg-color: #fdfdfd !default;
$sidebar-color: #131313 !default;
$sidebar-width: 270px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: #2b2a2a !default;
$submenu-bg-color: #fafafa !default;
$submenu-bg-color-collapsed: #ffffff !default;
$icon-bg-color: var(--icon-bg-color);
// $icon-bg-color: #0288d1 !default;
$icon-size: 30px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;

@import "~react-pro-sidebar/dist/scss/styles.scss";

.pro-sidebar {
  border: #d8d9dc solid 1px;
  box-shadow: 5px;
}
.pro-menu li:hover {
  background-color: #8444ff50;
}
.pro-sidebar .pro-menu .pro-menu-item li:hover {
  background-color: #8444ff50;
}
.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-list-item {
  border: #d8d9dc solid 1px;
  box-shadow: 5px;
}

.ps__rail-x,
.ps__rail-y {
  left: 0;
}

.blob {
  width: 89.6px;
  height: 89.6px;
  border: 11.2px solid #0000;
  background: radial-gradient(farthest-side, #474bff 98%, #0000) 0 0/22.4px
      22.4px,
    radial-gradient(farthest-side, #474bff 98%, #0000) 100% 0/22.4px 22.4px,
    radial-gradient(farthest-side, #474bff 98%, #0000) 100% 100%/22.4px 22.4px,
    radial-gradient(farthest-side, #474bff 98%, #0000) 0 100%/22.4px 22.4px,
    linear-gradient(#474bff 0 0) center/44.8px 44.8px, #fff;
  background-repeat: no-repeat;
  filter: blur(4.5px) contrast(10);
  animation: blob-xa27qk 0.8s infinite;
}

@keyframes blob-xa27qk {
  100% {
    background-position: 100% 0, 100% 100%, 0 100%, 0 0, center;
  }
}

// .filepond--item {
//   width: calc(33.33% - 0.5em);
//   height: 100px;
//   min-height: 80px;
//   margin: 0.25em;
//   border: 1px solid #ddd;
//   border-radius: 4px;
//   padding: 0.5em;
//   box-sizing: border-box;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   align-items: center;
//   position: relative;
//   background: #fff;
// }

.MuiFab-primary {
  display: none;
}

.imageCrop .box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}
.imageCrop .img-preview {
  overflow: hidden;
}

.css-7yx1g
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
  > .MuiDataGrid-cell {
  overflow: visible;
}

.css-1yixz7k-MuiDataGrid-root
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
  > .MuiDataGrid-cell {
  overflow: visible;
}
